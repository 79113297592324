<template>
    <section id="Emailservice" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>What we Do</h2>
          <p style="padding-top:20px">We market using email for businesses to sell products and to introduce a brand. It is one of the most cost-effective ways to market and has endless opportunities to develop a contact into a lifelong business partner or customer. </p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-3 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 style="font-size: 16px;">{{ obj.header }}</h4>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/Email.jpg',
        header: 'Promotional Campaign Email',
        caption: 'To promote a campaign, we make use of different ways to share the product we are promoting but approaching the email marketing strategy, we market by in such a way to grab the reader’s attention by the use of a content strategy to provoke a thought or add emotion.'
      },
      {
        path: 'services/Seasonal-Campaign.jpg',
        header: 'Seasonal Campaign',
        caption: 'This Campaign approach makes use of the seasonal celebrations and uses them as opportunities to send an email of our product. Using of various strategies like giving discounts, product based on the season and instilling a sense of urgency, makes the reader to get into action.'
      },
      {
        path: 'services/Automated-Email.jpg',
        header: 'Automated Email Series',
        caption: 'When considering a business, automated email service is used by companies with online buying options. Whenever a user goes without buying an added to cart product, an automated e-mail is generated by the computer encouraging them to complete the purchase. This is an effective way to capture the customer’s attention making them think twice.'
      },
      {
        path: 'services/Email-Marketing.jpg',
        header: 'Pros of Email Marketing',
        caption: 'The pros of email marketing are innumerable considering the fact that is free of cost and reaches the inboxes of our customers in a matter of minutes. It is result –oriented and focused as long as we are persistent with follow up mails and target based to reach out to people on a personal level to market our products.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Emailservice {
    padding: 50px 30px 10px 30px;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>