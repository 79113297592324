<template>
    <Header/>
    <Emailheader/>
    <Emailservice/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Emailservice from '@/components/Emailservice.vue'
import Emailheader from '@/components/Emailheader.vue'

export default {
  components: {
     Header,
    Footer,
    Emailservice,
    Emailheader
  }
}
</script>